import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ResponsiveAppBar from '../components/NavBar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import incentive from '../assets/images/incentive.png';
import creditCard from '../assets/images/credit-card.png';
import transfer from '../assets/images/transfer.png';
import ohand from '../assets/images/ohand.png';

import Footer from '../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../store/_Entities/User';


const Home = () => {
    const [spacing, setSpacing] = React.useState(11);
    const { user } = useSelector((state) => state.entities);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!user.data){
            dispatch(getUserData(user.authData.sub));
        }
    }, []);

    return (
        <>
            <ResponsiveAppBar />
            <Container maxWidth="md">
                <Grid
                    container
                    spacing={2}
                    className="home-comp"
                    sx={{ marginTop: '45px' }}
                >
                    <Grid xs={12}>
                        <h2>{user?.data?.company.toUpperCase()}</h2>
                    </Grid>
                    <Grid xs={12} sx={{ marginTop: '15px', width: '50%' }}>
                        <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            sx={{
                                fontSize: '15px',
                                color: '#686868'
                            }}
                        >
                            Defina os Benefícios/Wallets que sua empresa deseja disponibilizar e controlar nos cartões de Benefício (YOU) e Corporativo (CORP).
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    sx={{ flexGrow: 1, marginBottom: 30 }}
                    container
                    spacing={11}
                >
                    <Grid item xs={12}>
                        <Grid
                            container
                            justifyContent="start"
                            spacing={spacing}
                            className="card-home"
                        >
                            <Grid item>
                                <Paper className="card-paper">
                                    <img src={incentive} alt="" />
                                    <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            marginTop: '15px'
                                        }}
                                    >
                                        Benefícios
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        sx={{
                                            fontSize: '15px',
                                            color: '#686868'
                                        }}
                                    >
                                        Configure os benefícios que ficarão
                                        disponíveis
                                    </Typography>
                                    <Link
                                        className="link-home"
                                        href="/benefits"
                                    >
                                        Acessar
                                    </Link>
                                </Paper>
                            </Grid>

                            <Grid item>
                                <Paper className="card-paper">
                                    <img src={creditCard} alt="" />
                                    <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            marginTop: '15px'
                                        }}
                                    >
                                        Saldo Livre
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        sx={{
                                            fontSize: '15px',
                                            color: '#686868'
                                        }}
                                    >
                                        Controle o uso do saldo livre na sua
                                        empresa
                                    </Typography>
                                    <Link
                                        className="link-home"
                                        href="/free-balance"
                                    >
                                        Acessar
                                    </Link>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Grid
                            container
                            justifyContent="start"
                            spacing={spacing}
                            className="card-home"
                        >
                            <Grid item>
                                <Paper className="card-paper-no-margin">
                                    <img src={transfer} alt="" />
                                    <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            marginTop: '15px'
                                        }}
                                    >
                                        Parametrizar transferência
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        sx={{
                                            fontSize: '15px',
                                            color: '#686868'
                                        }}
                                    >
                                        Configure as regras de transferência entre benefícios
                                    </Typography>
                                    <Link
                                        className="link-home"
                                        href="/transfer-configuration"
                                    >
                                        Acessar
                                    </Link>
                                </Paper>
                            </Grid>

                            <Grid item>
                                <Paper className="card-paper-no-margin">
                                    <img src={ohand} alt="" />
                                    <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            marginTop: '15px'
                                        }}
                                    >
                                        Tarifas
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        sx={{
                                            fontSize: '15px',
                                            color: '#686868'
                                        }}
                                    >
                                        Visualize as tarifas cobradas
                                    </Typography>
                                    <Link
                                        className="link-home"
                                        href="/fares"
                                    >
                                        Acessar
                                    </Link>
                                </Paper>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default Home;
