import React, { useEffect, useState } from 'react';

import './assets/styles/main.css';

import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import Login from './containers/Login';
import Home from './containers/Home';
import Benefits from './containers/Benefits';
import TransferConfiguration from './containers/TransferConfiguration';
import FreeBalance from './containers/FreeBalance';
import Admin from './containers/Admin/Admin';
import SelectNewAdmin from './containers/Admin/SelectNewAdmin';

import Account from './containers/Account';
import Address from './containers/Address';
import Cards from './containers/Cards';
//Balance
import Balance from './containers/Balance/Balance';
import AddBalance from './containers/Balance/AddBalance';
import AddBalanceByTed from './containers/Balance/AddBalanceByTed';
import AddBalanceByPix from './containers/Balance/AddBalanceByPix';
import AddBalanceByBoleto from './containers/Balance/AddBalanceByBoleto';
// Order
import Orders from './containers/Order/Orders';
import AddIndividualOrderSelectUser from './containers/Order/AddIndividualOrder/SelectUser';
import AddIndividualOrderCreateNewOrder from './containers/Order/AddIndividualOrder/CreateNewOrder';
import AddIndividualOrderChoosePaymentOption from './containers/Order/AddIndividualOrder/ChoosePaymentOption';
// Collaborators
import AddCollaborator from './containers/Collaborators/AddCollaborator';
import UserAutoRegistration from './containers/Collaborators/UserAutoRegistration';
import DetailCollaborator from './containers/Collaborators/DetailCollaborator';
import Collaborators from './containers/Collaborators/Collaborators';
import BatchAddCollaborator from './containers/Collaborators/BatchAddCollaborator';

// Wrapper
import { PageContainer } from './components/wrappers/PageContainer';
// Store
import { useSelector, useDispatch } from 'react-redux';
import { getUserData, USER_LOGOUT } from './store/_Entities/User';

// Hooks
import useAutoLogout from './hooks/useAutoLogout';

// MUI
import { Alert, Snackbar } from '@mui/material';
import Transaction from './containers/Transactions';
import BatchAddOrder from './containers/Order/BatchingOrder/BatchAddOrder';
import BatchingLog from './containers/Order/BatchingOrder/BatchingLog';
import ApproveTranfer from './containers/Order/AproveTransfer';
import SelectCompany from './containers/SelectCompany';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import InviteCollaboratorForm from './containers/Collaborators/InviteCollaboratorForm';
import Fares from './containers/Fares';

const MAX_IDLE_TIME_MS = 2.4e6; // 40 minutes
const IDLE_WARNING_TIME_MS = 60000; // 1 minute

function App() {
    const { user } = useSelector((state) => state.entities);

    const idleTimer = useAutoLogout(MAX_IDLE_TIME_MS);

    const [openIdleTimeWarningSnackbar, setOpenIdleTimeWarningSnackbar] =
        useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const autoLogoutUser = () => {
        setOpenIdleTimeWarningSnackbar(false);
        dispatch(USER_LOGOUT());
        navigate('/login');
    };

    useEffect(() => {
        const date1 = new Date(user.loginDate);
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        if (diffTime > MAX_IDLE_TIME_MS) autoLogoutUser();
        if (idleTimer <= IDLE_WARNING_TIME_MS)
            setOpenIdleTimeWarningSnackbar(true);
        if (idleTimer === MAX_IDLE_TIME_MS)
            setOpenIdleTimeWarningSnackbar(false);
        if (idleTimer === 0) autoLogoutUser();
    }, [idleTimer]);

    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        user.isLoggedIn === false ? (
                            <Navigate to="/login" />
                        ) : (
                            <Navigate to="/home" />
                        )
                    }
                />

                <Route
                    path="/login"
                    element={
                        <GoogleReCaptchaProvider reCaptchaKey="6Le6G8gpAAAAAOZm2dAjGJxqmiLDZKMUN3cEMCHX">
                            <Login />
                        </GoogleReCaptchaProvider>
                    }
                />

                <Route
                    path="/select-company"
                    element={
                        <PageContainer protected>
                            <SelectCompany />
                        </PageContainer>
                    }
                />

                <Route
                    path="/home"
                    element={
                        <PageContainer protected>
                            <Home />
                        </PageContainer>
                    }
                />

                <Route
                    path="/benefits"
                    element={
                        <PageContainer protected>
                            <Benefits />
                        </PageContainer>
                    }
                />

                <Route
                    path="/free-balance"
                    element={
                        <PageContainer protected>
                            <FreeBalance />
                        </PageContainer>
                    }
                />

                <Route
                    path="/admin"
                    element={
                        <PageContainer protected>
                            <Admin />
                        </PageContainer>
                    }
                />
                <Route
                    path="/admin/add"
                    element={
                        <PageContainer protected>
                            <SelectNewAdmin />
                        </PageContainer>
                    }
                />

                <Route
                    path="/collaborators"
                    element={
                        <PageContainer protected>
                            <Collaborators />
                        </PageContainer>
                    }
                />
                <Route
                    path="/collaborators/add"
                    element={
                        <PageContainer protected>
                            <AddCollaborator />
                        </PageContainer>
                    }
                />
                <Route
                    path="/collaborators/invite/:companyID"
                    element={
                        <PageContainer protected>
                            <InviteCollaboratorForm />
                        </PageContainer>
                    }
                />
                <Route
                    path="/user/register"
                    element={
                        <PageContainer protected allRoles>
                            <UserAutoRegistration />
                        </PageContainer>
                    }
                />
                <Route
                    path="/user/edit"
                    element={
                        <PageContainer protected allRoles>
                            <UserAutoRegistration />
                        </PageContainer>
                    }
                />
                <Route
                    path="/collaborators/detail/:userID"
                    element={
                        <PageContainer protected>
                            <DetailCollaborator />
                        </PageContainer>
                    }
                />
                <Route
                    path="/collaborators/batching"
                    element={
                        <PageContainer protected>
                            <BatchAddCollaborator />
                        </PageContainer>
                    }
                />

                <Route
                    path="/balance"
                    element={
                        <PageContainer protected>
                            <Balance />
                        </PageContainer>
                    }
                />
                <Route
                    path="/balance/add"
                    element={
                        <PageContainer protected>
                            <AddBalance />
                        </PageContainer>
                    }
                />
                <Route
                    path="/balance/add/ted"
                    element={
                        <PageContainer protected>
                            <AddBalanceByTed />
                        </PageContainer>
                    }
                />
                <Route
                    path="/balance/add/pix"
                    element={
                        <PageContainer protected>
                            <AddBalanceByPix />
                        </PageContainer>
                    }
                />
                <Route
                    path="/balance/add/boleto"
                    element={
                        <PageContainer protected>
                            <AddBalanceByBoleto />
                        </PageContainer>
                    }
                />

                <Route
                    path="/orders"
                    element={
                        <PageContainer protected>
                            <Orders />
                        </PageContainer>
                    }
                />
                <Route
                    path="/orders/add/individual"
                    element={
                        <PageContainer protected>
                            <AddIndividualOrderSelectUser />
                        </PageContainer>
                    }
                />
                <Route
                    path="/orders/add/individual/:userID"
                    element={
                        <PageContainer protected>
                            <AddIndividualOrderCreateNewOrder />
                        </PageContainer>
                    }
                />
                <Route
                    path="/orders/add/individual/:userID/payment"
                    element={
                        <PageContainer protected>
                            <AddIndividualOrderChoosePaymentOption />
                        </PageContainer>
                    }
                />
                <Route
                    path="/orders/add/batch"
                    element={
                        <PageContainer protected>
                            <BatchAddOrder />
                        </PageContainer>
                    }
                />
                <Route
                    path="/orders/batching-log/:batchingID"
                    element={
                        <PageContainer protected>
                            <BatchingLog />
                        </PageContainer>
                    }
                />
                <Route
                    path="/orders/approve-tranfer"
                    element={
                        <PageContainer protected>
                            <ApproveTranfer />
                        </PageContainer>
                    }
                />
                <Route
                    path="/cards"
                    element={
                        <PageContainer protected>
                            <Cards />
                        </PageContainer>
                    }
                />

                <Route
                    path="/account"
                    element={
                        <PageContainer protected>
                            <Account />
                        </PageContainer>
                    }
                />

                <Route
                    path="/address"
                    element={
                        <PageContainer protected>
                            <Address />
                        </PageContainer>
                    }
                />

                {/* Redirect when not found */}
                <Route
                    path="*"
                    element={
                        <PageContainer protected>
                            <Navigate to="/home" />
                        </PageContainer>
                    }
                />
                <Route
                    path="/transaction"
                    element={
                        <PageContainer protected>
                            <Transaction />
                        </PageContainer>
                    }
                />
                <Route
                    path="/transfer-configuration"
                    element={
                        <PageContainer protected>
                            <TransferConfiguration />
                        </PageContainer>
                    }
                />
                <Route
                    path="/fares"
                    element={
                        <PageContainer protected>
                            <Fares />
                        </PageContainer>
                    }
                />
            </Routes>

            {openIdleTimeWarningSnackbar && (
                <Snackbar open={true}>
                    <Alert severity="warning" sx={{ width: '100%' }}>
                        Alerta de inatividade ! Você sera redirecionado para a
                        pagina de login em {idleTimer} segundos.
                    </Alert>
                </Snackbar>
            )}
        </>
    );
}

export default App;
